import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Form, Input, Row, Col, message } from "antd";
import { useTranslation } from "react-i18next";

import API from '../../api/api.js';
import { AuthContext } from '../../context/AuthContextProvider.tsx';

const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Register = () => {

  const { logInThroughEmail } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [ form ] = Form.useForm();
  const [ verifyCodeCooldown, setVerifyCodeCooldown ] = useState(0);

  // 发送验证码 30 秒倒计时
  useEffect(() => {
    if (verifyCodeCooldown === 0) return;
    const timerId = setInterval(() => {
      setVerifyCodeCooldown((prev) => (prev > 0 ? prev - 1 : 0));
    }, 1000);
    return () => clearInterval(timerId); // 清除定时器，防止内存泄漏
  }, [verifyCodeCooldown]);

  const handleGetVerifyCode = () => {

    const emailValid = form.getFieldError('email');
    if (emailValid.length > 0) {
      message.error(emailValid[0])
      return;
    }
    // 发送获得验证码请求
    API.get('/register/verify-email', {
      params: {
        'email': form.getFieldValue('email')
      },
    })
    .then(response => {
      message.success(t("验证码发送到您的邮箱"));
      setVerifyCodeCooldown(30);
    })
    .catch(error => {
      message.error(error);
      // message.error(error.response.data['error']);
      setVerifyCodeCooldown(30);
    });
  }

  const onSubmit = async (values) => {
    try {
      // 发送注册请求
      const data = {
        'email': values.email,
        'password': values.password,
        'verify-code': values.verificationCode,
      };
  
      await API.post('/register/email', data, {
        headers: { 'Content-Type': 'application/json' },
      });
  
      // 注册后登录
      message.success(t("注册成功！正在登录..."));
  
      await logInThroughEmail(values.email, values.password);
  
      navigate("/play");
    } catch (error) {
      if (!error.response) {
        message.error("Network error. Please check your connection.");
      } else {
        message.error(error.response.data['error']);
      }
    }
  };

  return (
    <Row>
      <Col span={8}></Col>
      <Col span={8}>
        <Form 
          {...formItemLayout}
          form={form} 
          name="register"
          onFinish={onSubmit}
        >
          <Form.Item
            name="email"
            label={t("邮箱")}
            rules={[
              {
                type: 'email',
                message: t('输入的邮箱不正确！'),
              },
              {
                required: true,
                message: t("请输入您的邮箱！"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="password"
            label={t("密码")}
            rules={[
              {
                required: true,
                message: t("请输入您的密码！"),
              },
              {
                pattern: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[A-Za-z0-9~@#S%*_\-+=:.?]{8,20}$/,
                message: t('密码须包含8-20个字符，包括大写字母、小写字母与数字'),
              }
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t("确认密码")}
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: t('请再次确认您的密码！'),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("两次输入的密码不一致！")));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item label={t("验证码")} extra={t("6位验证码将发送到您的邮箱")}>
            <Row gutter={8}>
              <Col span={12}>
                <Form.Item
                  name="verificationCode"
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: '请输入6位邮箱验证码！',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Button
                  disabled={verifyCodeCooldown !== 0} 
                  onClick={handleGetVerifyCode}
                >
                  {verifyCodeCooldown === 0 ? t("发送验证码") : t("重新发送")+`(${verifyCodeCooldown})`}
                </Button>
              </Col>
            </Row>
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button block type="primary" htmlType="submit">
              {t("注册")}
            </Button>
            {t("已有帐号？")} <a onClick={() => {navigate('/log-in')}}>{t("登录")}</a>
          </Form.Item>
        </Form>
      </Col>
      <Col span={8}></Col>
    </Row>
  );
}

export default Register;