import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { AuthContext } from '../../context/AuthContextProvider.tsx';
import { Form, Row, Col, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const AdminLogin = () => {

  const { adminLogInThroughUsername } = useContext(AuthContext);
  const navigate = useNavigate();

  const onSubmit = (values: any) => {
    adminLogInThroughUsername(
      values.username, values.password
    ).then(() => {
      navigate("/admin/dashboard");
    });
  };

  return (
    <Row>
      <Col span={9}></Col>
      <Col span={6}>
        <Form name="admin-login" onFinish={onSubmit}>
          <Form.Item name="username" rules={[{ required: true, message: '请输入管理员用户名！' }, ]}>
            <Input prefix={<UserOutlined />} placeholder="username" />
          </Form.Item>
          <Form.Item name="password" rules={[{ required: true, message: '请输入您的密码！', }, ]}>
            <Input prefix={<LockOutlined />} type="password" placeholder="password"/>
          </Form.Item>
          <Form.Item>
            <Button block type="primary" htmlType="submit">管理员登录</Button>
          </Form.Item>
        </Form>
      </Col>
      <Col span={9}></Col>
    </Row>
  );
}

export default AdminLogin;