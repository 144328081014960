import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Tabs, Result, Button } from 'antd';
import { SmileOutlined } from "@ant-design/icons";
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../context/AuthContextProvider.tsx';
import TranscriptionHistoryTab from './transcription/TranscriptionHistoryTab.jsx';
import MyLibrary from './library/MyLibrary.jsx';
import Vocabulary from './vocabulary/Vocabulary.tsx';

import "./ControlConsole.css";

const GuestDefaultTab = () => {

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return <Result 
          icon={<SmileOutlined />}
          title={t("登录解锁更多个性化听写功能！")}
          extra={<Button type="primary" onClick={() => {navigate("/log-in")}}>{t("登录")}</Button>}
        />
}

const ControlConsole = () => {

  const { t, i18n } = useTranslation();
  const { authHolder } = useContext(AuthContext);


  /**
   * 管理 Tab 状态
   */
  const [activeTab, setActiveTab] = useState('my-library-tab');
  const tabItems = [
    {
      key: 'my-library-tab',
      label: t("我的音频"),
      children: authHolder.roles.includes("USER")
        ? <MyLibrary activeTab={activeTab} setActiveTab={setActiveTab}/>
        : <GuestDefaultTab />
    },
    {
      key: 'transcription-history-tab',
      label: t("转录任务"),
      children: authHolder.roles.includes("USER")
        ? <TranscriptionHistoryTab activeTab={activeTab}/>
        : <GuestDefaultTab />,
    },
    {
      key: 'vocabulary-tab',
      label: t("单词本"),
      children: <Vocabulary activeTab={activeTab}/>
    }
  ];

  return (
    <div className="control_console_tab">
      <Tabs 
        activeKey={activeTab} 
        onTabClick={(key, event) => {setActiveTab(key);}}
        items={tabItems}
      />
    </div>
  );
}

export default ControlConsole;