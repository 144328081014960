import React from 'react';
import { Outlet } from 'react-router-dom';
import { Col, Row, Flex, Typography, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import './AuthLayout.css';

// Common background for '/login' and '/register'
const AuthLayout = () => {

  const { t, i18n } = useTranslation();

  return (
    <div 
      className='auth-background'
    >
      <Row
        style={{
          paddingTop: "80px",
          paddingBottom: "80px",
        }}
      >
        <Col span={8}></Col>
        <Col 
          span={8}
        >
          <Flex justify='center' align='center'>
            <Space size={'middle'}>
              <img 
                src='/img/logo.png'
                alt='logo'
                style={{
                  width: "45px",
                  height: "45px",
                }}
              />
              <span className='auth-title'>ECHO</span>
            </Space>
          </Flex>
          <Typography.Text
            type='secondary'
            style={{
              paddingTop: '10px',
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center'
            }}
          >
            {t("英语学习资源站")}
          </Typography.Text>
        </Col>
        <Col span={8}></Col>
      </Row>
      <Outlet />
    </div>
  )
}

export default AuthLayout;