import React, { useState, useEffect, useMemo } from 'react';
import { message } from 'antd';
import API from '../api/api.js';

export enum Role {
  ADMIN = "ADMIN",
  USER = "USER",
  GUEST = "GUEST"
}

export interface AppUser {
  username: string;
  nickname: string;
  email: string;
  balance: number;
  profileImg: string;
  registerTime: Date | null;
  roles: Role[];
}

const defaultAppUser: AppUser = {
  username: "",
  nickname: "Guest",
  email: "",
  balance: 0,
  profileImg: "",
  registerTime: null,
  roles: [Role.GUEST]
}

export const AuthContext = React.createContext<{
  authHolder: AppUser;
  setAuthHolder: React.Dispatch<React.SetStateAction<AppUser>>;
  logInThroughEmail: (email: string, password: string) => Promise<void>;
  adminLogInThroughUsername: (username: string, password: string) => Promise<void>;
  resetAuthHolder: () => void;
}>({
  authHolder: defaultAppUser,
  setAuthHolder: () => {},
  logInThroughEmail: async () => {},
  adminLogInThroughUsername: async () => {},
  resetAuthHolder: () => {}
});

const AuthContextProvider = ({ children }) => {

  const [ authHolder, setAuthHolder ] = useState<AppUser>(defaultAppUser);

  // 登录 handler
  const logInThroughEmail = async (email: string, password: string) => {
    const data = {
      'email': email,
      'password': password
    };
    try {
      const response = await API
        .post('/login/email', data, { headers: { 'Content-Type': 'application/json' } });
      // 登录成功 将用户信息存入 AuthContext 关闭 Modal
      const logInUser: AppUser = {
        username: response.data.username,
        nickname: response.data.nickname,
        email: response.data.email,
        balance: response.data.balance,
        profileImg: response.data.profileImg,
        registerTime: new Date(response.data.registerTime+"+00:00"),
        roles: response.data.roles.map((role: string) => Role[role as keyof typeof Role])
      };
      setAuthHolder(logInUser);
      message.success("Log in success!");
    } catch (error) {
      if (!error.response) {
        message.error("Network error. Please check your connection.");
      } else {
        message.error(error.response.data['error']);
      }
    }
  }

  // 登出 handler
  const resetAuthHolder = () => {
    setAuthHolder(defaultAppUser);
  }

  // 管理员登录 handler
  const adminLogInThroughUsername = async (username: string, password: string) => {
    try {
      const response = await API.post(
        '/admin/login',
        null,
        { 
          params: {
            'username': username,
            'password': password,
          }
        }
      )
      // 登陆成功
      const adminUser: AppUser = {
        username: response.data.username,
        nickname: response.data.nickname,
        email: response.data.email,
        balance: response.data.balance,
        profileImg: response.data.profileImg,
        registerTime: new Date(response.data.registerTime+"+00:00"),
        roles: response.data.roles.map((role: string) => Role[role as keyof typeof Role])
      };
      setAuthHolder(adminUser);
      message.success("Admin log in success!");
    } catch (error) {
      if (!error.response) {
        message.error("Network error. Please check your connection.");
      } else {
        message.error(error.response.data['error']);
      }
    }
  }

  // 初始化登陆状态，Cookie 未过期情况下尝试自动登录
  useEffect(() => {
    API
    .get('/return')
    .then(response => {
      const returnAppUser : AppUser = {
        username: response.data.username,
        nickname: response.data.nickname,
        email: response.data.email,
        balance: response.data.balance,
        profileImg: response.data.profileImg,
        registerTime: new Date(response.data.registerTime+"+00:00"),
        roles: response.data.roles.map((role: string) => Role[role as keyof typeof Role])
      }
      setAuthHolder(returnAppUser);
    })
    .catch(error => {
      // 用户没有 cookie 或 cookie 已失效
      // TODO: 后端查看到失效的cookie后将取消cookie
    })
  }, []);

  return (
    <AuthContext.Provider value={{
      authHolder,
      setAuthHolder,
      logInThroughEmail,
      adminLogInThroughUsername,
      resetAuthHolder
    }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;