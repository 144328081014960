import React, { useContext, useState, useEffect } from "react";
import { Divider, Modal, Button, Input, Typography, Row, Col, Upload, Popover, message, Avatar } from "antd";
import { LoadingOutlined, PlusOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

import { AuthContext } from '../../context/AuthContextProvider.tsx';
import API from '../../api/api.js';
import { formatTimeAgo } from '../../util/FormatUtil.jsx';

const UserProfileModal = ({ isOpen, setIsOpen }) => {

  const { authHolder, setAuthHolder } = useContext(AuthContext);
  const { t, i18n } = useTranslation();

  /*
    OSS 上传头像相关 State & Handler
  */
  /*
    OSS Upload MetaData: {
      dir: '/avatar',
      host: URL,
      policy: String,
      callback: String,
      accessId: String,
      signature: String,
      expiration: Long,
      x:username
    }
  */
  // const [ossMetaData, setOssMetaData] = useState(null);
  // const [isAvatarUploading, setIsAvatarUploading] = useState(false);

  // const getOssMetaData = async () => {
  //   try {
  //     const response = await API.get("/user/upload-avatar-policy");
  //     setOssMetaData(response.data);
  //   } catch (error) {
  //     // console.log(error);
  //     // message.error(error.response);
  //   }
  // }

  // // 打开Modal时获取上传元数据
  // useEffect(() => {
  //   if (isOpen) {
  //     getOssMetaData();
  //   }
  // }, [isOpen]);

  // const beforeUploadAvatar = async (file) => {
  //   // 检查文件形式是否正确
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     message.error(t("请上传 .jpg/.png 格式的文件！"));
  //     return false;
  //   }
  //   const isLt1M = file.size / 1024 / 1024 < 1;
  //   if (!isLt1M) {
  //     message.error(t("请上传小于 1MB 的图片文件！"));
  //     return false;
  //   }
  //   // 检查是否有最新的文件上传元数据
  //   if (!ossMetaData || Number(ossMetaData.expiration) * 1000 < Date.now()) {
  //     await getOssMetaData();
  //   };
  //   // 拼接文件上传路径
  //   const suffix = file.name.slice(file.name.lastIndexOf('.'));
  //   const filename = ossMetaData['x:username'] + suffix;
  //   file.url = ossMetaData.dir + filename;
  //   return file;
  // }

  // const handleChange = (info) => {
  //   if (info.file.status === 'uploading') {
  //     setIsAvatarUploading(true);
  //     return;
  //   }
  //   if (info.file.status === 'done') {
  //     setIsAvatarUploading(false);
  //     return;
  //   }
  // };

  // const onUploadError = (error) => {
  //   // console.log(error);
  // }

  // const onUploadSuccess = (response, file) => {
  //   const prevUser = authHolder.user;
  //   const newUser = {
  //     ...prevUser,
  //     profileImg: response["profile-img"],
  //   }
  //   setAuthHolder((prev) => ({
  //     ...prev,
  //     user: newUser,
  //   }));
  //   setIsAvatarUploading(false);
  //   message.success(t("头像已更新！"));
  // }

  // const buildOssRequest = (file) => {
  //   return {
  //     key: file.url,
  //     policy: ossMetaData.policy,
  //     callback: ossMetaData.callback,
  //     OSSAccessKeyId: ossMetaData.accessId,
  //     signature: ossMetaData.signature,
  //     success_action_status: '200',
  //     'x:username': ossMetaData['x:username']
  //   }
  // }

  // const uploadButton = (
  //   <button
  //     style={{
  //       border: 0,
  //       background: 'none',
  //     }}
  //     type="button"
  //   >
  //     {isAvatarUploading ? <LoadingOutlined /> : <PlusOutlined />}
  //     <div style={{ marginTop: 8, }} >
  //       {t("上传头像")}
  //     </div>
  //   </button>
  // );

  /*
    修改昵称相关 State & Handler
  */
  const [isEditingNickname, setIsEditingNickname] = useState(false);
  const [nicknameInput, setNicknameInput] = useState('');
  const [isChangeNicknameModalOpen, setIsChangeNicknameModalOpen] = useState(false);

  const nicknamePattern = /^[A-Za-z0-9_]{6,20}$/
  const handleNicknameConfirmClick = () => {
    // nickname 至少6位, 只能由 大小写字母、数字、下划线组成
    if (!nicknamePattern.test(nicknameInput)) {
      message.error(t("昵称长度 6-20 位，由英文字母、数字或下划线组成"));
      return;
    }
    setIsChangeNicknameModalOpen(true);
  }

  const handleChangeNicknameOk = () => {
    // 发起nickname 更新请求
    API
      .post("/user/nickname", null, {
        params: {
          nickname: nicknameInput,
        } 
      })
      .then((response) => {
        // nickname 请求成功
        // 更新 authHolder 中的 nickname 信息
        const prevUser = authHolder;
        const newUser = {
          ...prevUser,
          nickname: nicknameInput,
        }
        setAuthHolder(newUser);
        setIsChangeNicknameModalOpen(false);
        setIsEditingNickname(false);
        message.success(t("昵称修改成功！"));
      })
      .catch((error) => {
        setNicknameInput(authHolder.nickname);
        setIsChangeNicknameModalOpen(false);
        setIsEditingNickname(false);
        message.error("Network error!");
      });
  }

  const handleQuitNicknameEditing = () => {
    setIsEditingNickname(false);
    setNicknameInput(authHolder.nickname);
  } 

  /*
    充值相关 State & Handler
  */
  const [voucherCode, setVoucherCode] = useState("");
  const voucherPattern = /^[A-Z0-9]{20}$/
  const handleRedeem = () => {
    // 检查兑换码格式
    if (!voucherPattern.test(voucherCode)) {
      message.error(t("兑换码无效！"));
      return;
    }
    API
      .post("/balance/use-voucher", null, {
        params: {
          "voucher-code": voucherCode
        }
      })
      .then(() => {
        message.success(t("兑换成功！"));
        setVoucherCode("");
        return API.get("/balance");
      })
      .then((response) => {
        // console.log(response);
        const prevUser = authHolder;
        const newUser = {
          ...prevUser,
          balance: response.data["token"],
        }
        setAuthHolder(newUser);
      })
      .catch((error) => {
        if (!error.response) {
          message.error(error);
        } else {
          message.error(error.response.data["error"]);
        }
      });
  }

  /**
   * 充值相关：回音社区小助手
   */
  const voucherInfo = () => {
    return (
      <div>
        <Typography.Text>回音社区测试中！联系</Typography.Text>
        <Typography.Text strong>社区小助手（QQ: 2608780355）</Typography.Text>
        <Typography.Text>获取免费兑换码~</Typography.Text>
      </div>
    );
  }

  return (
    <Modal open={isOpen} onCancel={() => { setIsOpen(false); }} footer={null}>
      <Row justify="space-around" align="middle">
        <Col span={4}>
          {/* <Upload
            name="file"
            listType="picture-circle"
            className="avatar-uploader"
            showUploadList={false}
            action={ossMetaData?.host}
            data={buildOssRequest}
            beforeUpload={beforeUploadAvatar}
            onChange={handleChange}
            onError={onUploadError}
            onSuccess={onUploadSuccess}
          >
            {uploadButton}
          </Upload> */}
          <Avatar 
            size={60}
            icon={<UserOutlined />}
          />
        </Col>
        <Col span={20}>
            {isEditingNickname ?
            <Row justify="space-around" align="middle">
              <Col span={12}>
                <Input placeholder={t("修改昵称")} onChange={(e) => {setNicknameInput(e.target.value)}}/>
              </Col>  
              <Col span={4}>
                <Button size="small" onClick={handleNicknameConfirmClick} type="primary">{t("确认")}</Button>
              </Col>
              <Col span={4}>
                <Button size="small" onClick={handleQuitNicknameEditing}>{t("取消")}</Button>
              </Col>
              <Modal open={isChangeNicknameModalOpen} onOk={handleChangeNicknameOk} onCancel={() => {setIsChangeNicknameModalOpen(false);}}> 
                <p>{t("你确定要修改昵称")+`: '${nicknameInput}' ?`}</p>
              </Modal>
            </Row>
            :
            <Row justify="space-around" align="middle">
              <Col span={12}>
                <Typography.Text strong> {authHolder.nickname}</Typography.Text>
              </Col>
              <Col span={12}>
                <Button size="small" onClick={() => {setIsEditingNickname(true)}}>{t("修改昵称")}</Button>
              </Col>
            </Row>
            }
          <Row>
            <Typography.Text type="secondary">{t("邮箱")+" :"+authHolder.email}</Typography.Text>
          </Row>
          <Row>
            <Typography.Text type="secondary">{t("注册时间")+": "+formatTimeAgo(authHolder.registerTime)}</Typography.Text>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={6}>
          <Typography.Text strong>{t("账户余额")+" "}</Typography.Text>
        </Col>
        <Col span={6}>
          <Typography.Text type="warning">{authHolder.balance}</Typography.Text>
        </Col>
      </Row>
      <Row>
        <Typography.Text><br />{t("使用兑换码")}</Typography.Text>
      </Row>
      <Row>
        <Col span={17}>
          <Input placeholder={t("输入兑换码")} onChange={(e) => {setVoucherCode(e.target.value)}}/>
        </Col>
        <Col span={1}>
          <div></div>
        </Col>
        <Col span={6}>
          <Button type="primary" onClick={handleRedeem}>{t("兑换")}</Button>
        </Col>
      </Row>
      <Popover content={voucherInfo} trigger="click">
        <Typography.Link>如何获取兑换码？</Typography.Link>
      </Popover>
    </Modal>
  );
}

export default UserProfileModal;